import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Colors } from 'constants/colors';
import { fontPrimary } from 'constants/font-family';

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    placement={props.placement ? props.placement : 'top'}
    classes={{ popper: className }}
    sx={{
      maxWidth: '550px !important',
    }}
  />
))(({ theme, color }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: Colors.whiteTransparent03,
    boxShadow: `0px 5px 5px 0px ${
      color ? Colors.tooltipBox : Colors.tableBorder
    }`,
    '&::before': {
      boxShadow: `0px 5px 5px 0px ${
        color ? Colors.tooltipBox : Colors.tableBorder
      }`,
      border: `1px solid ${color ? color : Colors.tooltipBorder}`,
    },
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: Colors.cardBackground,
    maxWidth: '550px',
    boxShadow: `10px 4px 10px 4px ${
      color ? Colors.tooltipBox : Colors.tableBorder
    }`,
    borderRadius: '5px',
    border: `1.5px solid ${color ? color : Colors.tooltipBorder}`,
    color: Colors.white,
    fontFamily: fontPrimary,
  },
}));
